// get menuButtons

const menuId = 'navItems';

const openMenu = document.querySelector('#menuOpen');

openMenu.addEventListener('click', () => {
    toggleShow(menuId);
})

const linkContact = document.querySelector('#linkIntern').addEventListener('click', () => {
    const menu = document.getElementById(menuId);
    if (menu.classList.contains('show')) {
        toggleShow(menuId);
    };
})

function toggleShow(id) {
    const menu = document.getElementById(id);
    menu.classList.toggle('show');
    openMenu.classList.toggle('active');
    noScroll();
}

function noScroll() {
    document.body.classList.toggle('noScroll');
}